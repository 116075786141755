/* Target the about page */
.about-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(100% - 70px);
    overflow-y: scroll;
    overflow-x: hidden;
}

/* Target the title container */
.about-title-container{
    display: flex;
    width: 80vw;
    background-color: var(--color-supporting-black);
    border: 1px solid rgb(63, 63, 63);
    border-radius: 2rem;
    margin-top: 20px;
    padding: 50px;
    margin-bottom: 20px;
}

/* Target the image container */
.about-image-container{
    display: flex;
    position: relative;
    width: 25%;
    min-width: 250px;
    height: calc(20vw * 1.33);
    min-height: 333px;
    opacity: 0;
    box-shadow: -20px 20px 0px var(--color-dark-gold);
    animation: opacity-fade-in 2s cubic-bezier(0.25, 0.1, 0.25, 1) 0.25s forwards;
}

/* Target the image in image container */
.about-image-container > img{
    z-index: 2;
    border-radius: 0.5rem;
    width: 100%;
    height: 100%;
}

/* Target text container */
.about-text-container{
    width: 70%;
    height: 100%;
    max-height: max(250px * 1.33, calc(80vw * 0.3) * 1.33);
    margin-left: 50px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: rgb(255, 255, 255);
    scrollbar-width: 2px;
}

/* Target scroll bar */
.about-text-container::-webkit-scrollbar{
    background-color: rgb(255, 255, 255);
    width: 2px;
}

/* Target all p childs in text container */
.about-text-container > p{
    color: rgb(200, 200, 200);
    font-size: max(1rem, 1.75vw);
    opacity: 0;
}

/* Target third child */
.about-text-container > :nth-child(3){
    animation: fade-in-left 1s 0.25s ease-out forwards;
}

/* Target 5th child */
.about-text-container > :nth-child(5){
    animation: fade-in-right 1s 0.5s ease-out forwards;
}

/* Target resume button p tags (needed for firefox support) */
.about-resume-button > p{
    opacity: 0;
    animation: fade-in-right 1s 0.5s ease-out forwards;
}

/* Target arrow down icon */
.about-folder-arrow{
    width: 30px;
    height: 30px;
    color: var(--color-dark-gold);
    padding-right: 5px;
}

/* Target first child in text container */
.about-text-container > :first-child{
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    animation: fade-in-right 1s 0.15s forwards;
}

/* Target the first child after element in text container */
.about-text-container > :first-child::after{
    content: " ";
    background-color: gold;
    margin-left: 10px;
    width: 50%;
    max-width: 200px;
    height: 3px;
    border-radius: 3rem;
}

/* Target the about resume container */
.about-resume-container{
    height: 130px;
    display: flex;
    align-items: center;
    opacity: 0;
}

/* Target the about resume download button */
.about-resume-button {
    width: fit-content;
    height: fit-content
}

/* Target the input tag in the about resume button */
.about-resume-button input {
    display: block;
    height: 50px;
    width: 200px;
    color: white;
    font: 17px/50px sans-serif,Helvetica;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    background: #355C7D; 
}

/* Target the p tag in the about resume button */
.about-resume-button > p {
    background: rgb(153, 134, 25);
    display: block;
    height: 40px;
    width: 180px;
    margin: -50px 0 0 10px;
    text-align: center;
    font: 14px/45px sans-serif,Helvetica;
    color: #eee;
    position: absolute;
    z-index: -1;
    -webkit-transition: margin 0.7s cubic-bezier(0.6, -0.28, 0.74, 0.05);
    -moz-transition: margin 0.7s cubic-bezier(0.6, -0.28, 0.74, 0.05);
    -o-transition: margin 0.7s cubic-bezier(0.6, -0.28, 0.74, 0.05);
    -ms-transition: margin 0.7s cubic-bezier(0.6, -0.28, 0.74, 0.05);
    transition: margin 0.7s cubic-bezier(0.6, -0.28, 0.74, 0.05);
}

/* Target both the input and p tags in the about resume button */
.about-resume-button > input, .about-resume-button > p {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 13px;
    -webkit-box-shadow: 2px 2px 8px rgba(0,0,0,0.2);
    -moz-box-shadow: 2px 2px 8px rgba(0,0,0,0.2);
    box-shadow: 2px 2px 8px rgba(0,0,0,0.2);
}

.about-resume-button > input:first-child{
    border: 2px solid white;
}

/* Target resume button bottom on hover */
.about-resume-button:hover .about-resume-button-bottom { 
  margin: -10px 0 0 10px 
}

/* Target resume button top on hover */
.about-resume-button:hover .about-resume-button-top {
    margin: -80px 0 0 10px;
    line-height: 35px;
}

/* 660px */
@media (max-width: 660px){
    .about-title-container{
        flex-direction: column;
        align-items: center;
        width: 80vw;
        min-width: 275px;
        height: fit-content;
        min-height: fit-content;
        padding: 20px;
    }

    .about-image-container{
        min-height: fit-content;
        height: calc(64vw * 1.33);
    }

    .about-image-container{
        width: 80%;
    }

    .about-text-container{
        margin-top: 50px;
        width: 100%;
        max-height: fit-content;
        margin-left: 0;
        overflow-y: visible;
    }
}


