html, body, #root{
    background: rgb(36,36,36);
    height: 100%;
    width: 100%;
    overflow: hidden;
}

*{
    margin: 0;
    padding: 0;
}

:root{
    --color-dark-gold: rgb(185, 176, 67);
    --color-opaque-navy-blue: rgb(0, 150, 255, 0.2);
    --color-main-black: rgb(36, 36, 36);
    --color-supporting-black: rgb(50, 50, 50);
}

p {
    font-family: 'Montserrat',sans-serif;
}

/* Custom scrollbar */
::-webkit-scrollbar {
    width: 10px;
}
    
::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-radius: 10px;
}
    
::-webkit-scrollbar-thumb:hover {
    background: #000000;
}
    
::-webkit-scrollbar-track {
    background:#d4d4d4;
    border-radius: 10px;
}