/* Target skills container */
.skills-container{
    width: 90%;
    opacity: 0;
    animation: fade-in-right 1s 0.5s forwards;
}

/* Target the first element */
.skills-container > :first-child{
    margin-bottom: 20px;
}

/* Target all p childs in the first element */
.skills-container > :first-child > p{
    color: white;
    font-size: 1.75rem;
    display: flex;
    align-items: center;
}

/* Add a before to the p tag */
.skills-container > :first-child > p::before{
    content: " ";
    display: inline-flex;
    width: 80px;
    height: 3px;
    background-color: gold;
    border-radius: 3rem;
    margin-right: 10px;
}

/* Target skills button container */
.skills-button-container{
    display: flex;
}

/* Target all buttons */
.skills-button-container > div > button{
    padding: 10px 20px 10px 20px;
    background: none;
    font-size: 1.5rem;
    font-weight: bold;
    border: none;
    color:white;
    opacity: 0.5;
    border-radius: 0.5rem;
}

/* Target only active buttons */
.skills-button-container > div > .type-active{
    opacity: 1;
    background-color: var(--color-opaque-navy-blue);
}

/* Target buttons on hover */
.skills-button-container > div > button:not(.type-active):hover{
    opacity: 1;
}

/* Target the skills grid */
.skills-grid-container{
    margin-top: 20px;
    margin-bottom: 50px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 180px);
    gap: 15px 15px;

}

/* Target the card container */
.skills-card-container{
    display: flex;
    width: 130px;
    padding: 10px 20px 10px 20px;
    border: 1px solid gold;
    border-radius: 0.75rem;
}

/* Target the skills image container */
.skills-image{
    width: 50px;
    height: 50px;
}

/* Target the skills image */
.skills-image > img{
    width: 100%;
    height: 100%;
}

/* Target the skills text container */
.skills-text{
    display: flex;
    align-items: center;
    margin-left: 10px;
}

/* Target the skills text */
.skills-text > p{
    color: white;
    font-weight: bold;
}

/* 660px */
@media (max-width: 660px){
    .skills-image{
        width: 35px;
        height: 35px;
    }
    .skills-text > p{
        font-size: 0.8rem;
    }

    .skills-card-container{
        width: 100px;
    }

    .skills-grid-container{
        grid-template-columns: repeat(auto-fill, 150px);
    }

}