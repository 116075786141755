/* Target Home Container */
.home-container{
    display: flex;
    height: calc(100% - 70px);
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

/* Target the text container */
.home-text-container{
    width: fit-content;
    margin-top: 5px;
}

/* Target all p elements in the text container */
.home-text-container > p{
    font-size: max(3rem, 5vw);
    color: white;
    font-weight: bold;
    opacity: 0;
    animation: fade-in-down 1s 1s forwards;
}

/* Target the dynamic text container */
.dynamic-text{
    display: flex;
    opacity: 0;
    animation: fade-in-up 1s 1s forwards;
}

/* Target all p elements in the dynamic text container */
.dynamic-text > p{
    font-size: max(2.5rem, 3vw);
    color: white;
}

/* Targte only the dynamic text in the dynamic text container */
.dynamic-text > :nth-child(2){
    margin-left: 10px;
    background: linear-gradient(to right, rgb(255,255,255), rgb(255, 210, 0));
    -webkit-text-fill-color: transparent;
    background-clip: text;
    position: relative;
}

/* Create a blinking cursor */
.dynamic-text > :nth-child(2):after{
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    border-left: 3px solid white;
    animation: cursor-blink 1.5s infinite;
}

/* Target the button container */
.home-button-container{
    display: flex;
    align-items: center;
    opacity: 0;
    animation: fade-in-right 1s 1.5s forwards;
}

/* Target the contact button container */
.contact-button-container{
    padding: 30px;
}

/* Contact Button */
.contact-button-container > input {
  --b: 3px;   /* border thickness */
  --s: .45em; /* size of the corner */
  --color: var(--color-dark-gold);

  padding: calc(.5em + var(--s)) calc(.9em + var(--s));
  color: white;
  --_p: var(--s);
  background:
    conic-gradient(from 90deg at var(--b) var(--b),#0000 90deg,var(--color) 0)
    var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p));
  transition: .3s linear, color 0s, background-color 0s;
  outline: var(--b) solid #0000;
  outline-offset: .6em;
  font-size: 16px;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

/* Contact button */
.contact-button-container > input:hover,
.contact-button-container > input:focus-visible{
  --_p: 0px;
  outline-color: var(--color);
  outline-offset: .05em;
}

/* Contact button */
.contact-button-container > input:active {
  background: var(--color);
  color: white;
}

/* Quote button */
.quote-button-container{
    border-radius: 0.5rem;
}

/* Quote button */
.quote-button-container > input{
    background: none;
    border: none;
    color: white;
    padding: 10px;
}

/* Quote button */
.quote-button-container:hover{
    background-color: var(--color-opaque-navy-blue);
}

/* Target the home image container */
.home-image-container{
    display: flex;
    position: relative;
    width: 25vw;
    min-width: 300px;
    opacity: 0;
    animation: fade-in-up 1s 1s forwards;
    margin-bottom: 50px;
}

/* Target all direct images in the home image container */
.home-image-container > img{
    z-index: 2;
    border-radius: 0.5rem;
    width: 100%;
    height: 100%;
}

/* Target the first image in the home image container */
.home-image-one{
    opacity: 1;
}

/* Target the second image in the home image container */
.home-image-two{
    position: absolute;
    opacity: 0;
}

/* An animation to add to the home image carousel */
.home-image-carousel-left-out{
    animation: carousel-left-out 1s 0s forwards linear;
}

/* An animation to add to the home image carousel */
.home-image-carousel-left-in{
    animation: carousel-left-in 1s 0s forwards linear;
}

/* Create the offset background behind the image */
.home-image-background{
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--color-supporting-black);
    bottom: -10%;
    left: 10%;
    z-index: 1;
}

/* Create the text box in front of the image */
.home-image-text-container{
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 20%;
    min-height: 100px;
    min-width: 150px;
    background-color: var(--color-dark-gold);
    z-index: 3;
    bottom: 0;
    left: -20%;
    overflow-y: scroll;
    scrollbar-width: none;
}

/* Target the confetti */
.explosion-container{
    position: absolute;
    left: 50%;
    top: 50%;
}

/* Target the image text box */
.home-image-text-container::-webkit-scrollbar{
    display: none;
}

/* Target the image text box */
.home-image-text-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    height: fit-content;

}

/* Target all p elements in the the image text box */
.home-image-text-container p{
    font-size: 15px;
    color: rgb(255, 255, 255);
}

/* Target the home location container */
.home-location-container{
    display: flex;
    width: fit-content;
    opacity: 0;
    align-items: center;
    margin-top: 20px;
    animation: fade-in-up 1s 1s forwards;
}

/* Target the home location container on hover */
.home-location-container:hover{
    cursor: pointer;
}

/* Target the home location container text on hover */
.home-location-container p:hover{
    color: white;
}

/* Target the location inside the home location container */
.home-location-container > p{
    font-style: italic;
    color: rgb(200, 200, 200);
}

/* Target the map pin icon */
.home-map-pin-icon {
    width: 25px;
    height: 25px;
    color: var(--color-dark-gold);
}

/* Fade out right animation */
.home-fade-out-right{
    animation: long-fade-out-right 750ms 0ms ease-in-out forwards;
}

/* Fade back in from the right animation */
.home-fade-out-right-reverse{
    transform: translateX(100px);
    opacity: 0;
    animation: long-fade-out-right-100-reverse 750ms 200ms ease-in-out forwards;
}

/* Fade out left animation */
.home-fade-out-left{
    animation: long-fade-out-left 750ms 0s ease-in-out forwards;
}

/* Fade back in from the left animation */
.home-fade-out-left-reverse{
    opacity: 0;
    transform: translateX(-100px);
    animation: long-fade-out-left-100-reverse 750ms 200ms ease-in-out forwards;
}

/* Fade down animation */
.home-map-fade-in-down{
    opacity: 0;
    transform: translateY(-200px);
    animation: fade-in-down-100 750ms 300ms ease-in-out forwards;
}

/* Fade back in from below animation */
.home-map-fade-in-down-reverse{
    opacity: 1;
    animation: fade-in-down-100-reverse 750ms 0ms ease-in-out forwards;
}

/* Fade up animation */
.home-map-fade-in-up{
    opacity: 0;
    transform: translateY(200px);
    animation: fade-in-up-100 750ms 300ms ease-in-out forwards;
}

/* Fade back in from above animation */
.home-map-fade-in-up-reverse{
    opacity: 1;
    transform: translateY(0);
    animation: fade-in-up-100-reverse 750ms 0s ease-in-out forwards;
}

/* Target the home map container */
.home-map-container{
    position: absolute;
    width: 70%;
    height: 70%;
    display: flex;
    flex-direction: column;
}

/* Target the home map */
.home-map{
    opacity: 0;
    width: 100%;
    height: 100%;
    background-color: white;
}

/* Target the exit map button */
.home-exit-map-button {
  appearance: none;
  background-color: #FFFFFF;
  border-width: 0;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-family: Clarkson,Helvetica,sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1em;
  margin: 0;
  opacity: 1;
  outline: 0;
  padding: 1.5em 2.2em;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-rendering: geometricprecision;
  text-transform: uppercase;
  transition: opacity 300ms cubic-bezier(.694, 0, 0.335, 1),background-color 100ms cubic-bezier(.694, 0, 0.335, 1),color 100ms cubic-bezier(.694, 0, 0.335, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
}

/* Target the exit map button */
.home-exit-map-button:before {
  animation: opacityFallbackOut .5s step-end forwards;
  backface-visibility: hidden;
  background-color: var(--color-dark-gold);
  clip-path: polygon(-1% 0, 0 0, -25% 100%, -1% 100%);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateZ(0);
  transition: clip-path .5s cubic-bezier(.165, 0.84, 0.44, 1), -webkit-clip-path .5s cubic-bezier(.165, 0.84, 0.44, 1);
  width: 100%;
}

/* Target the exit map button on hover */
.home-exit-map-button:hover:before {
  animation: opacityFallbackIn 0s step-start forwards;
  clip-path: polygon(0 0, 101% 0, 101% 101%, 0 101%);
}

/* Target the exit map button */
.home-exit-map-button:after {
  background-color: #FFFFFF;
}

/* Target the exit map button span */
.home-exit-map-button span {
  z-index: 1;
  position: relative;
}

/* 680px */
@media (max-width: 680px){
    .home-text-container > p{
        font-size: max(1rem, 8vw);
    }

    .dynamic-text > p{
        font-size: max(0.8rem, 5.5vw);
    }

    .home-location-container > p{
        font-size: max(0.8rem, 2.5vw);
    }

    .home-map-pin-icon {
        width: 20px;
        height: 20px;
    }
}

/* 550px */
@media (max-width: 550px){
    .home-image-text-container{
        left: 0;
        width: calc(100% - 20px);
    }

}
